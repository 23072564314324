import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import '@google/model-viewer';
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import $ from 'jQuery';

let camera, controls, scene, renderer;
let primitiveMaterial, plywood, model;
const section = document.getElementById('section');
const loadingContainer = document.getElementById('loadingContainer');
const siteContainer = document.getElementById('site');
const scroll = document.getElementById('body');
const loader = new GLTFLoader();
const btns = document.querySelectorAll(".acc-btn");
const beginbtn = document.getElementById("begin");
const loadingBar = document.getElementById("LoadingBar");
let width = screen.width;


// section.addEventListener('mousemove', (e) =>{
//   const moveX = (e.pageX * -1 / 100);
//   const moveY = (e.pageY * -1 / 100);

//   section.style.backgroundPosition = moveX + 'px ' + moveY + 'px';
// });

gsap.registerPlugin(ScrollTrigger);

const sections = gsap.utils.toArray(".section");
const container = document.querySelector('.bg-container');


window.enterSite = function(){
        scroll.style.overflowY = "scroll";
        scene.remove( model);
        unfade(siteContainer);
}

function fade(element) {
    var op = 1;  // initial opacity
    var timer = setInterval(function () {
        if (op <= 0.1){
            clearInterval(timer);
            element.style.display = 'none';
        }
        element.style.opacity = op;
        element.style.filter = 'alpha(opacity=' + op * 100 + ")";
        op -= op * 0.1;
    }, 50);
    
}

function unfade(element) {
    loadingContainer.style.display = "none";
    siteContainer.style.visibility = "visible";
    var op = 0.1;  // initial opacity
    element.style.display = 'block';
    var timer = setInterval(function () {
        if (op >= 1){
            clearInterval(timer);
            scene.remove( model);
        }
        element.style.opacity = op;
        element.style.filter = 'alpha(opacity=' + op * 100 + ")";
        op += op * 0.03;
    }, 10);
}

gsap.set(container, {
    backgroundColor: '#05002b',
})


const tl = gsap.timeline({

    scrollTrigger: {
        trigger: "SO",
        scrub: true,
    }

})

tl
    .to(container, { duration: 1, backgroundColor: '#05002b', ease: "none" }, 0)
    .to(container, { duration: 1, backgroundColor: '#8a82d1', ease: "none" }, 1)

// GLOBAL VARIABLES
//Particles
const material = new THREE.PointsMaterial({ size: 1.0 });
const particlesGeometry = new THREE.BufferGeometry;
const particlesCount = 10000;
const posArray = new Float32Array(particlesCount * 3);
const particleMesh = new THREE.Points(particlesGeometry, material);

// Set the date we're counting down to
var countDownDate = new Date("July 7, 2022 14:00:00").getTime();

// Update the count down every 1 second
var x = setInterval(function() {

  // Get today's date and time
  var now = new Date().getTime();
    
  // Find the distance between now and the count down date
  var distance = countDownDate - now;
    
  // Time calculations for days, hours, minutes and seconds
  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    
  // Output the result in an element with id="demo"
  document.getElementById("countdown").innerHTML = days + "d " + hours + "h "
  + minutes + "m " + seconds + "s ";
    
  // If the count down is over, write some text 
  if (distance < 0) {
    clearInterval(x);
    document.getElementById("countdown").style.display = "none";
  }
}, 1000);

init();
render();


function init() {
    // Canvas
    const canvas = document.querySelector('canvas.webgl')

    // Scene
    scene = new THREE.Scene()
 //   scene.background = new THREE.Color("rgb(5, 0, 43)");
    // Materials
    // "rgb(255,192,203)"

    const material = new THREE.MeshBasicMaterial()
    material.color = new THREE.Color(0xff0000)


    // Lights
    const light = new THREE.AmbientLight(0xffffff, 0.5); // soft white light
    scene.add(light);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(1 * 50, 0.75 * 50, 0.5 * 50).normalize();
    directionalLight.castShadow = true;
    scene.add(directionalLight);

    //Particles
    for (let i = 0; i < particlesCount * 3; i++) {
        posArray[i] = (Math.random() - 0.5) * 5000;
    }

    particlesGeometry.setAttribute('position', new THREE.BufferAttribute(posArray, 3));
    scene.add(particleMesh);

    primitiveMaterial = new THREE.MeshLambertMaterial({ color: 0xfffff0, map: plywood });

   // Load Geo
        loader.load('/block-examples-anim.glb', function (gltf) {
        model = gltf.scene;
        // Construct array of children during traversal of gltf object
        gltf.scene.traverse(function (child) {
            if (child.isMesh) {
                child.scale.set(15, 15, 15);
            }
        });

        scene.add( model);
        loadingBar.style.display = "none";
        if($(window).width() > 600)
        {
            beginbtn.style.display = "flex"
        }
        else
        {
            beginbtn.style.display = "none";
        }
    });

    // const gridHelper = new THREE.GridHelper(1000, 20);
    // scene.add(gridHelper);
    /**
     * Sizes
     */
    const sizes = {
        width: window.innerWidth,
        height: window.innerHeight
    }

    window.addEventListener('resize', () => {
        // Update sizes
        sizes.width = window.innerWidth
        sizes.height = window.innerHeight

        // Update camera
        camera.aspect = sizes.width / sizes.height;

        camera.left = (sizes.width * camera.aspect) / -2;
        camera.right = (sizes.width * camera.aspect) / 2;
        camera.top = (sizes.height * camera.aspect) / 2;
        camera.bottom = (sizes.height * camera.aspect) / -2;
        camera.updateProjectionMatrix();
    

        // Update renderer
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    })

    // Base camera

    //camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 1000)
    camera = new THREE.OrthographicCamera(sizes.width / - 2, sizes.width / 2, sizes.height / 2, sizes.height / - 2, 1, 1000);
    camera.lookAt(0, 0, 0)
    camera.position.x = 250
    camera.position.y = 300
    camera.position.z = 250
    camera.updateProjectionMatrix()
    scene.add(camera)

    // Controls
    controls = new OrbitControls(camera, canvas)
    controls.enableDamping = true;
    controls.enablePan = false;
    controls.minDistance = 150;
    controls.maxDistance = 500;
    controls.enabled = false;
    controls.autoRotate = true;

    //Renderer
    renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        antialias: true, 
        alpha: true,
    })
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    animate();
}

function animate() {
    window.requestAnimationFrame(animate)
    controls.update();
    particleMesh.rotateY(0.0003);
    render();
}

function render() {
    renderer.render(scene, camera)
}

function accordion() {
    // this = the btn | icon & bg changed
    this.classList.toggle("is-open");
  
    // the acc-content
    const content = this.nextElementSibling;
  
    // IF open, close | else open
    if (content.style.maxHeight) content.style.maxHeight = null;
    else content.style.maxHeight = content.scrollHeight + "px";
  }
  
  // event
  btns.forEach((el) => el.addEventListener("click", accordion));